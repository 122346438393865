import * as React from 'react';
import Head from 'next/head';
import { TopPage } from '@nutmeglabs/falcon-ui';
import { FalconUIContextProvider } from 'components/FalconUIContextProvider/FalconUIContextProvider';
import { ReduxState } from 'ducks';
import { useSelector } from 'react-redux';
import { OgpTags } from './OgpTags';
import dynamic from 'next/dynamic';
import '@nutmeglabs/falcon-ui/dist/esm/index.css';

import './CustomTopPage.css';
import { useRouter } from 'next/router';
import { ApiKeyContext } from 'contexts/ApiKeyContext';
import { selectHeaderDescriptionText, selectIsPMP } from 'lib/util/privateMarketplace';
import { PopupMessage } from 'components/PopupMessage/PopupMessage';
import { selectBookingWidgetHeaderDescription } from 'lib/util/bookingWidgetDesign';
import { visibleProductSummariesSelector } from 'lib/util/productSummarySelector';

interface Props {
  topPageData: TopPage;
}

const Header = dynamic(() => import('@nutmeglabs/falcon-ui').then((mod) => mod.Header));
const Footer = dynamic(() => import('@nutmeglabs/falcon-ui').then((mod) => mod.Footer));
const KeyVisual = dynamic(() => import('@nutmeglabs/falcon-ui').then((mod) => mod.KeyVisual));
const SectionView = dynamic(() => import('@nutmeglabs/falcon-ui').then((mod) => mod.SectionView));

export const CustomTopPage = ({ topPageData }: Props) => {
  const router = useRouter();
  const { apiKey } = React.useContext(ApiKeyContext);
  const isPMP = useSelector(selectIsPMP);
  const settings = useSelector((state: ReduxState) => state.server.settings.all);
  const operatingHoursRules = settings?.operating_hours_rules;
  let operatingHoursDescription = '';
  if (operatingHoursRules && operatingHoursRules.length > 0) {
    operatingHoursDescription = `${operatingHoursRules[0].start_time_local} - ${operatingHoursRules[0].end_time_local}`;
  }
  const products = useSelector(visibleProductSummariesSelector);

  let path = router.asPath.replace(`/${apiKey}`, '');
  if (isPMP && router.pathname === '/top') {
    path = '/top';
  }
  const bwPageTitle = useSelector(selectBookingWidgetHeaderDescription) || settings.supplier_name;
  const pmpPageTitle = useSelector(selectHeaderDescriptionText);

  const pageTitle = isPMP ? pmpPageTitle : bwPageTitle;

  const canonicalDomain = settings?.canonical_domain;
  const canonicalUrl = canonicalDomain ? `https://${canonicalDomain}${path}` : '';
  const iconUrl = settings.icon_url || '';

  return (
    <FalconUIContextProvider>
      <PopupMessage />
      <Head>
        <title>{pageTitle}</title>
        {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}
        {iconUrl && <link rel="shortcut icon" href={iconUrl} />}
        {iconUrl && <link rel="apple-touch-icon" href={iconUrl} />}
      </Head>

      <OgpTags topPageData={topPageData} />
      <div className="base-wrap" style={{ background: '#fff' }}>
        <Header data={topPageData} pageDescriptionTag="h1" />

        <main className="base-main" style={{ marginBottom: 0 }}>
          <KeyVisual data={topPageData} />

          {topPageData.sections?.map((section, index) => {
            return (
              <SectionView key={index} data={section} products={products as any} optimizeImages />
            );
          })}
        </main>

        <Footer
          data={topPageData}
          orgInfo={{
            phone: settings?.supplier_phone ?? '',
            email: settings?.supplier_email ?? '',
            address: settings?.supplier_address ?? '',
            operatingHoursDescription,
            hideFooterJapaneseTermsLink:
              settings?.booking_widget_design_params?.hide_footer_japanese_terms_link ?? false,
            snsIds: settings?.sns_ids ?? [],
            showTravelAgencyRegistrationForm:
              settings?.reservation_payment_gateway_settings?.payment_gateway === 'GMO',
          }}
        />
      </div>
    </FalconUIContextProvider>
  );
};
